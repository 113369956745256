<template>
  <div class="home_container>">
    <div class="col-12 text-center">
      <h2>Demander ma retraite en ligne</h2>
      <hr />
    </div>
    <button class="btn" @click="goBackToHome">Retour à l'accueil</button>
    <div class="row justify-content-center">
      <div class="col-12 text-center intro-text">
        <p class="listepj">
          Préparez votre demande de retraite en ligne en vérifiant les documents
          qui seront nécessaires pour cette démarche.
        </p>
      </div>
      <div class="col-12 text-center message">{{ message }}</div>
    </div>

    <div class="row justify-content-center">
      <div class="col text-cpt">
        Consultez la liste de vos justificatifs
      </div>
    </div>

    <form>
      <!-- BLOC NATIONALITE -->
      <BlocNat :form_data="form_data" :form_libelle="form_libelle" @setNat="(nat) => setPJ_nat(nat)"
        @setBoolNat="bool_nat = $event" />

      <!-- BLOC SITUATION FAMILIALE -->
      <BlocSF :form_data="form_data" :form_libelle="form_libelle" @setPJ_sf="(picked_sf) => setPJ_sf(picked_sf)" />

      <!-- BLOC AVEZ-VOUS DES ENFANTS -->
      <div class="row justify-content-center">
        <div class="col-md-10 ml-auto mr-auto form_bloc">
          <fieldset>
            <legend class="bloc-title">Avez-vous des enfants ?</legend>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="is_parent" id="is_parent" value="is_parent"
                v-model="form_data_enf.picked_parent" v-on:change="showFirstChildForm()" />
              <label class="form-check-label" for="is_parent">Oui</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="is_parent" id="is_not_parent" value="is_not_parent"
                v-model="form_data_enf.picked_parent" v-on:change="showFirstChildForm()" />
              <label class="form-check-label" for="is_not_parent">Non</label>
            </div>
          </fieldset>
        </div>
      </div>

      <!-- BLOC ENFANT-->
      <div v-show="showFormEnfants">
        <div v-for="(enfant, index) in liste_enfants" v-bind:key="index" class="row justify-content-center">
          <div class="col-md-10 ml-auto mr-auto form_bloc" v-bind:id="'enf_' + index" :ref="`enf_${index}`">
            <p style="position: relative" class="bloc-title" v-if="index == 0">
              1er enfant :
              <span class="close" @click="deleteEnfant(index)">&times;</span>
            </p>
            <p style="position: relative" class="bloc-title" v-else>
              {{ index + 1 }}<sup>ème</sup> enfant :
              <span class="close" @click="deleteEnfant(index)">&times;</span>
            </p>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'enfant.enf_bio' + index"
                :id="'enfant.enf_bio' + index" :value="enfant.enf_bio" v-model="form_data_enf.picked_enf_id[index]"
                v-on:change="setPJ_enf()" />
              <label class="form-check-label" :for="'enfant.enf_bio' + index">Biologique
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'enfant.enf_adopt' + index"
                :id="'enfant.enf_adopt' + index" :value="enfant.enf_adopt" v-model="form_data_enf.picked_enf_id[index]"
                v-on:change="setPJ_enf()" />
              <label class="form-check-label" :for="'enfant.enf_adopt' + index">{{ form_libelle.enf_adopt }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'enfant.enf_dp' + index" :id="'enfant.enf_dp' + index"
                :value="enfant.enf_dp" v-model="form_data_enf.picked_enf_id[index]" v-on:change="setPJ_enf()" />
              <label class="form-check-label" :for="'enfant.enf_dp' + index">{{
      form_libelle.enf_dp
    }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'enfant.enf_rec' + index"
                :id="'enfant.enf_rec' + index" :value="enfant.enf_rec" v-model="form_data_enf.picked_enf_id[index]"
                v-on:change="setPJ_enf()" />
              <label class="form-check-label" :for="'enfant.enf_rec' + index">{{
      form_libelle.enf_rec
    }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'enfant.enf_tutel' + index"
                :id="'enfant.enf_tutel' + index" :value="enfant.enf_tutel" v-model="form_data_enf.picked_enf_id[index]"
                v-on:change="setPJ_enf()" />
              <label class="form-check-label" :for="'enfant.enf_tutel' + index">{{ form_libelle.enf_tutel }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'enfant.enf_cjt' + index"
                :id="'enfant.enf_cjt' + index" :value="enfant.enf_cjt" v-model="form_data_enf.picked_enf_id[index]"
                v-on:change="setPJ_enf()" />
              <label class="form-check-label" :for="'enfant.enf_cjt' + index">{{
      form_libelle.enf_cjt
    }}</label>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-10 ml-auto mr-auto">
            <button type="button" class="btn btn-lg btn-block btn_enfant" @click="addEnfant">
              Ajouter un enfant à la liste
            </button>
          </div>
        </div>
      </div>

      <!-- BLOC ANCIENS COMBATTANTS etc -->
      <BlocSitPart :form_data="form_data" @setPJ_maladie="(form_data) => setPJ_maladie(form_data)"
        @setPJ_chomage="(form_data) => setPJ_chomage(form_data)" @setPJ_hcp="(form_data) => setPJ_hcp(form_data)" />
    </form>

    <!-- BLOC DISPLAY DOCS -->
    <div class="row justify-content-center" v-show="showDocs" id="showDocs" ref="showDocs">
      <div class="col-md-10 ml-auto mr-auto form_bloc" id="listPJ">
        <p class="listepj">Justificatifs à prévoir :</p>
        <h3>
          Retrouvez ci-dessous les justificatifs que vous devrez fournir au
          moment de faire votre demande de retraite en ligne.
        </h3>
        Voici les documents nécessaires :
        <ul>
          <li v-for="PJNominal in listPJNominal" :key="PJNominal">
            {{ capitalize(PJNominal) }}
            <ModalComponent v-if="PJNominal ==
      'Copie de votre acte de naissance avec mention marginale datant de moins de 3 mois'
      " url_image="/pj_exemple/acte_de_naissance.png" :PjTitle="capitalize(PJNominal)" :PjId="'Actedenaissance'" />
            <ModalComponent v-if="PJNominal == 'deux derniers avis d’impôt sur le revenu'"
              url_image="/pj_exemple/avis_impot.png" :PjTitle="capitalize(PJNominal)" :PjId="'Impot'" />
            <ModalComponent v-if="PJNominal == '12 derniers bulletins de salaires (si vous êtes salarié)'"
              url_image="/pj_exemple/specimen_fiche_de_paie.png" :PjTitle="capitalize(PJNominal)"
              :PjId="'BulletinSalaire'" />
            <ModalComponent v-if="PJNominal == 'relevé d\'identité bancaire (RIB ou RICE) à votre nom sous format IBAN'"
              url_image="/pj_exemple/specimen_rib.png" :PjTitle="capitalize(PJNominal)" :PjId="'RIB'" />
          </li>
          <li v-show="bool_nat">
            {{ capitalize(listPJLongLabel.nat) }}
            <ModalComponent url_image="/pj_exemple/titre_sejour.jpg" :PjTitle="this.listPJLongLabel.nat"
              :PjId="'Veuf'" />
          </li>
          <li v-show="bool_SF.bool_marie">
            {{ capitalize(listPJLongLabel.sf_marie) }}
          </li>
          <!-- <li v-show="bool_SF.bool_pacs">
            {{ capitalize(listPJLongLabel.sf_pacs) }}
          </li> -->
          <li v-show="bool_SF.bool_div">
            {{ capitalize(listPJLongLabel.sf_div) }}
          </li>

          <!-- LISTE DES PJ POUR LES ENFANTS -->

          <li v-for="(enf, index) in form_data_enf.picked_enf_id" v-bind:key="index">
            <span v-if="enf == 'enf_bio' && index == 0">
              1er enfant : {{ listPJ.enf_bio }}
            </span>
            <span v-if="enf == 'enf_bio' && index !== 0">{{ index + 1 }}<sup>ème</sup> enfant : {{ listPJ.enf_bio }}
            </span>
            <span v-if="enf == 'enf_adopt' && index == 0">
              1er enfant : {{ listPJ.enf_adopt }}
            </span>
            <span v-if="enf == 'enf_adopt' && index !== 0">
              {{ index + 1 }}<sup>ème</sup> enfant :
              {{ listPJ.enf_adopt }}
            </span>
            <span v-if="enf == 'enf_dp' && index == 0">
              1er enfant : {{ listPJ.enf_dp }}
            </span>
            <span v-if="enf == 'enf_dp' && index !== 0">
              {{ index + 1 }}<sup>ème</sup> enfant : {{ listPJ.enf_dp }}
            </span>
            <span v-if="enf == 'enf_rec' && index == 0">
              1er enfant : {{ listPJ.enf_rec }}
            </span>
            <span v-if="enf == 'enf_rec' && index !== 0">
              {{ index + 1 }}<sup>ème</sup> enfant : {{ listPJ.enf_rec }}
            </span>
            <span v-if="enf == 'enf_tutel' && index == 0">
              1er enfant : {{ listPJ.enf_tutel }}
            </span>
            <span v-if="enf == 'enf_tutel' && index !== 0">
              {{ index + 1 }}<sup>ème</sup> enfant :
              {{ listPJ.enf_tutel }}
            </span>
            <span v-if="enf == 'enf_cjt' && index == 0">1er enfant : {{ listPJ.enf_cjt }}
            </span>
            <span v-if="enf == 'enf_cjt' && index !== 0">
              {{ index + 1 }}<sup>ème</sup> enfant : {{ listPJ.enf_cjt }}
            </span>
          </li>

          <!-- FIN DES PJ POUR LES ENFANTS -->

          <li v-show="bool_hcp">
            {{ capitalize(part_hcp(listPJ.hcp, 0)) }}
            ou
            {{ part_hcp(listPJ.hcp, 1) }}
            <span>
              <ModalComponent url_image="/pj_exemple/cotorep.png" :PjId="'cotorep'"
                :PjTitle="capitalize(part_hcp(listPJ.hcp, 1))" />
            </span>
            ou
            {{ part_hcp(listPJ.hcp, 2) }}
            <span>
              <ModalComponent url_image="/pj_exemple/notification-mdph.png" :PjId="'notification-mdph'"
                :PjTitle="capitalize(part_hcp(listPJ.hcp, 2))" />
            </span>
            ou
            {{ part_hcp(listPJ.hcp, 3) }}
            <span>
              <ModalComponent url_image="/pj_exemple/cmi_invalidite.png" :PjId="'cmi_invalidite'"
                :PjTitle="capitalize(part_hcp(listPJ.hcp, 3))" />
            </span>
          </li>
          <li v-show="bool_ac">
            {{ capitalize(listPJ.ac) }}
          </li>
          <li v-show="bool_maladie">
            {{ capitalize(listPJ.maladie) }}
          </li>
          <li v-show="bool_chomage">
            {{ capitalize(listPJ.chomage) }}
          </li>
        </ul>
        <h3>
          D'autres documents pourront vous être demandés au cours de votre
          démarche.
        </h3>
        <div class="row gap-3 justify-content-center mt-3">
          <div class="col-auto">
            <a class="btn lien" href="https://www.info-retraite.fr/portail-services/drl" target="_blank"
              rel="noopener noreferrer" v-on:click="$emit('notify', 'accesDril')"><i class="fa fa-lock"></i> Demander ma
              retraite</a>
          </div>
          <div class="col-auto">
            <div class="btn" v-on:click="generatePDF">Enregistrer en PDF</div>
          </div>
          <div class="col-auto">
            <div class="btn" v-on:click="goToTop">Retour en haut de page</div>
          </div>
        </div>
      </div>
    </div>
    <!--FIN BLOC DISPLAY DOCS -->
    <!-- BLOC INFO SCAN -->
    <BlocInfoScan />
    <!--FIN BLOC INFO SCAN -->
  </div>
</template>

<script>
import jspdf from "jspdf";
import ModalComponent from "../components/ModalComponent.vue";
import BlocNat from "../components/shared/BlocNatComponent.vue";
import BlocInfoScan from "../components/shared/BlocInfoScanComponent.vue";
import BlocSF from "../components/DRIL/BlocSFComponent.vue";
import BlocSitPart from "../components/DRIL/BlocSitPartComponent.vue";

export default {
  components: {
    ModalComponent,
    BlocNat,
    BlocInfoScan,
    BlocSF,
    BlocSitPart,
  },
  data() {
    return {
      api_url: process.env.MIX_APP_API_URL,
      form_data: {
        picked_nat: null,
        picked_sf: "",
        picked_hcp: false,
        picked_ac: false,
        picked_maladie: false,
        picked_chomage: false,
      },
      form_data_enf: { picked_enf_id: [], picked_parent: null },
      listDocs: [],
      form_libelle: { nat: "" },
      listPJ: {},
      listPJNominal: [],
      listPJLongLabel: [],
      message: "",
      cpt: 0,
      urlImage: "./images/documents.svg",
      showDocs: true,
      showFormEnfants: false,
      bool_nat: false,
      bool_hcp: false,
      bool_maladie: false,
      bool_chomage: false,
      bool_ac: false,
      bool_SF: {
        bool_marie: false,
        bool_pacs: false,
        bool_div: false,
      },
      array_enfants: [],
      liste_enfants: [
        {
          id: 0,
          enf_bio: "enf_bio",
          enf_adopt: "enf_adopt",
          enf_dp: "enf_dp",
          enf_rec: "enf_rec",
          enf_tutel: "enf_tutel",
          enf_cjt: "enf_cjt",
        },
      ],
    };
  },
  created() {
    this.getPJs();
    this.changeTitle();
  },
  beforeUpdate() {
    let pj_entries = Object.keys(this.listPJ);
    //on est obligé de ne pas compter les enfants car on a pas pu ajouter à la liste des PJ la concat enf_+index
    //to study later
    pj_entries = pj_entries.filter(function (item) {
      return !item.includes("enf_");
    });
    if (this.array_enfants.length >= 1) {
      let cpt_enf = this.array_enfants.length;
      this.cpt = pj_entries.length + cpt_enf - 1; //on retire le cas_nominal
    } else this.cpt = pj_entries.length - 1; //on retire le cas_nominal
  },
  methods: {
    changeTitle() {
      var title = document.querySelector("#title");
      title.innerHTML = "Justificatifs à fournir";
    },
    goBackToHome() {
      this.$emit("notify", "/");
      this.$router.push("/");
    },
    getOldKey(prefix_key) {
      //on recupere sous forme de tableau les clés de l'obj
      const props = Object.keys(this.listPJ);
      let old_picked_key = props.find((key) => {
        let index = key.indexOf(prefix_key);
        if (index !== -1) return key;
      });
      return old_picked_key;
    },
    showFirstChildForm() {
      let parent = this.form_data_enf.picked_parent;
      if (parent == "is_parent") this.showFormEnfants = true;
      else {
        this.showFormEnfants = false;
        //on supprime tous les enfants
        this.form_data_enf.picked_enf_id.splice(0);
        this.deletePJ_enfs();
      }
    },
    addEnfant() {
      let id = this.liste_enfants.length;
      this.liste_enfants.push({
        id: id,
        enf_bio: "enf_bio",
        enf_adopt: "enf_adopt",
        enf_dp: "enf_dp",
        enf_rec: "enf_rec",
        enf_tutel: "enf_tutel",
        enf_cjt: "enf_cjt",
      });
    },
    deleteEnfant(indice) {
      this.liste_enfants.splice(indice, 1);
      this.form_data_enf.picked_enf_id.splice(indice, 1);
    },
    displayDocs() {
      //this.showDocs = !this.showDocs;
      let element = this.$refs["showDocs"];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
    //not used;
    getImgUrl() {
      return "./images/documents.svg";
    },
    getPJ(field) {
      return this.listDocs.find((item) => {
        return item.short_label_pj == field;
      });
    },
    setPJ_enf() {
      //je nettoie toutes les anciennes valeurs
      this.deletePJ_enfs();
      //je récupère les nouvelles
      this.array_enfants = this.form_data_enf.picked_enf_id;
      //je complète la liste des PJ
      this.array_enfants.map((enf, key) => {
        if (enf == "enf_bio") {
          this.setEnfBioPJ(enf);
        } else {
          let pj = this.getPJ(enf);
          if (pj) {
            //this.listPJ[pj.short_label_pj+'_'+key] = pj.long_label_pj;
            this.listPJ[pj.short_label_pj] = pj.long_label_pj;
          }
        }
      });
    },
    deletePJ_enfs() {
      //je récupère la liste des clés "enf_" déjà présente
      const props = Object.keys(this.listPJ);
      let old_props = props.filter((key) => {
        return key.includes("enf_");
      });
      //console.log(old_props);
      //on vire les clés séjà existantes
      if (old_props.length > 0) {
        old_props.map((key) => {
          delete this.listPJ[key];
        });
      }
      //console.log(this.listPJ);
    },
    //keep but not used => to inform user globally
    setPJ_enf_old() {
      this.dedeletePJ_enfs();
      //on recupere la valeur de v-model pour chaque enf
      let array_enfants = this.form_data_enf.picked_enf_id;
      //je vire les doublons
      let unique_array_enfants = [...new Set(array_enfants)];
      //console.log(unique_array_enfants);

      //j'actualise la liste des PJ
      unique_array_enfants.map((enf) => this.setPJ(enf));
    },
    setPJ_nat() {
      //je récupère le choix du user
      let nat = this.form_data.picked_nat;
      //si le choix est fr pas de PJ nat
      if (nat == "fr") delete this.listPJ.nat;
      //j'ajoute la PJ que si ce n'est pas fr et que ce n'est pas déja inside'
      if (!this.listPJ[nat] && nat !== "fr") this.setPJ(nat);
    },
    setPJ_sf() {
      let sf = this.form_data.picked_sf;
      //on recherche la dernière picked_sf enregitrée pour l'effacer
      let old_picked_sf = this.getOldKey("sf_");
      if (old_picked_sf) {
        //remove old_picked_sf
        delete this.listPJ[old_picked_sf];
      }
      this.setBoolSF(sf);
      //on ajoute la pièce jointe correspondante sauf s'il s'agit d'un single OU d'un pacsé
      if (sf !== "single" && sf !== "sf_pacs") this.setPJ(sf);
      this.setEnfBioPJ("enf_bio");
    },
    // découpe la phrase pour le cas invalidité et renvoie la partie demandée
    part_hcp(txt, n) {
      if (txt) {
        const parts = txt.split(' ou ')
        return parts[n]
      }
      return ""
    },
    setPJ_hcp(form_data) {
      if (form_data.picked_hcp) {
        this.setPJ("hcp");
        this.bool_hcp = true;
      } else {
        delete this.listPJ.hcp;
        this.bool_hcp = false;
      }
    },
    setPJ_ac(form_data) {
      if (form_data.picked_ac) {
        this.setPJ("ac");
        this.bool_ac = true;
      } else {
        delete this.listPJ.ac;
        this.bool_ac = false;
      }
    },
    setPJ_maladie(form_data) {
      if (form_data.picked_maladie) {
        this.setPJ("maladie");
        this.bool_maladie = true;
      } else {
        delete this.listPJ.maladie;
        this.bool_maladie = false;
      }
    },
    setPJ_chomage(form_data) {
      if (form_data.picked_chomage) {
        this.setPJ("chomage");
        this.bool_chomage = true;
      } else {
        delete this.listPJ.chomage;
        this.bool_chomage = false;
      }
    },
    getLabels() {
      this.listDocs.map((item) => {
        this.form_libelle[item.short_label_pj] = item.situation;
      });
    },
    setBoolSFToFalse() {
      for (const bool in this.bool_SF) {
        this.bool_SF[bool] = false;
      }
    },
    setBoolSF(sf) {
      if (sf) {
        switch (sf.slice(3)) {
          case "marie":
            this.setBoolSFToFalse();
            this.bool_SF.bool_marie = true;
            break;
          case "pacs":
            this.setBoolSFToFalse();
            this.bool_SF.bool_pacs = true;
            break;
          case "div":
            this.setBoolSFToFalse();
            this.bool_SF.bool_div = true;
            break;
          default:
            this.setBoolSFToFalse();
            break;
        }
      } else {
        this.setBoolSFToFalse();
      }
    },
    initlistPJ() {
      let pj = this.getPJ("cas_nominal");
      if (pj) this.listPJ["cas_nominal"] = pj.long_label_pj;
      this.listPJNominal = this.listPJ["cas_nominal"].split(" | ");
    },
    setPJ(field) {
      //field not nec !
      //on veut ajouter la pJ <=> une situation sous la forme un clé valeur
      let pj = this.getPJ(field);
      //console.log(pj);
      if (pj) {
        //let objPJ = `{ ${field}:${pj.long_label_pj} }`;
        // this.listPJ = Object.assign({}, this.listPJ, objPJ);
        // console.log(this.listPJ);
        this.listPJ[field] = pj.long_label_pj;
      }
    },
    initListPJLongLabel(datas) {
      for (let i = 0; i < datas.length; i++) {
        this.listPJLongLabel[datas[i].short_label_pj] = datas[i].long_label_pj;
      }
    },
    setEnfBioPJ(enf) {
      let pj1 = this.getPJ("enf_bio1");
      let pj2 = this.getPJ("enf_bio2");
      if (pj1 && pj2) {
        if (this.form_data_enf.picked_enf_id.length > 0) {
          if (this.form_data.picked_sf !== "sf_marie") {
            this.listPJ[enf] = pj1.long_label_pj;
          } else {
            this.listPJ[enf] = pj2.long_label_pj;
          }
        }
      }
    },
    getPJs() {
      axios
        .get(`${this.api_url}`)
        .then((response) => {
          // let i = this.reponses.map((data) => data.id).indexOf(id);
          // this.reponses.splice(i, 1);
          //on recupère l'ensemble des pièces jointes pour toutes les siutations
          this.listDocs = response.data;
          //on récupère les libelles de toutes les situations
          this.getLabels();
          this.initlistPJ();
          this.initListPJLongLabel(this.listDocs);
          //window.scrollTo(0,0);
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            this.message =
              "Une erreur est survenue, nous vous invitons à revenir plus tard";
          }
        });
    },
    capitalize(str) {
      // converting first letter to uppercase
      const capitalized = str
        ? str.charAt(0).toUpperCase() + str.slice(1)
        : null;

      return capitalized;
    },
    generatePDF() {
      this.$emit("notify", "PJDril");
      const divPJ = document.getElementById("listPJ");
      var doc = new jspdf("p", "mm", "a4");
      doc.setFontSize(12);

      // All units are in the set measurement for the document
      // This can be changed to "pt" (points), "mm" (Default), "cm", "in"
      var tabPJ = [];
      for (const key in this.listPJ) {
        tabPJ.push(key);
      }
      doc.text("Voici les documents dont vous avez besoin :", 20, 20);
      for (let i = 0; i < this.listPJNominal.length; i++) {
        doc.rect(10, (i + 2) * 10 + 5, 5, 5);
        doc.text(this.capitalize(this.listPJNominal[i]), 20, (i + 3) * 10);
      }
      var cpt = 0;
      for (let i = 0; i < tabPJ.length; i++) {
        const line = tabPJ[i];
        if (line != "cas_nominal") {
          if (line === "enf_bio") {
            if (this.form_data.picked_sf === "sf_marie") {
              let enfBioIsMarie = this.getPJ("enf_bio2").long_label_pj;
              doc.rect(10, (cpt + 6) * 10 + 5, 5, 5);
              doc.text(this.capitalize(enfBioIsMarie), 20, (cpt + 7) * 10);
              cpt++;
            } else if (this.form_data.picked_sf !== "sf_marie") {
              let enfBioIsNoMarie1 = this.getPJ(
                "enf_bio1"
              ).long_label_pj.substr(0, 51);
              let enfBioIsNoMarie2 =
                this.getPJ("enf_bio1").long_label_pj.substr(51);
              doc.rect(10, (cpt + 6) * 10 + 5, 5, 5);
              doc.text(this.capitalize(enfBioIsNoMarie1), 20, (cpt + 7) * 10);
              cpt++;
              doc.text(enfBioIsNoMarie2, 20, (cpt + 7) * 10);
              cpt++;
            }
          } else if (line === "hcp") {
            doc.rect(10, (cpt + 6) * 10 + 5, 5, 5);
            let str1 = this.listPJ[line].substr(
              0,
              this.listPJ[line].search("ou")
            );
            let str2 = this.listPJ[line].substr(
              this.listPJ[line].search("ou"),
              57
            );
            let str3 = this.listPJ[line].substr(
              this.listPJ[line].lastIndexOf("ou")
            );
            doc.text(this.capitalize(str1), 20, (cpt + 7) * 10);
            cpt++;
            doc.text(str2, 20, (cpt + 7) * 10);
            cpt++;
            doc.text(str3, 20, (cpt + 7) * 10);
            cpt++;
          } else {
            doc.rect(10, (cpt + 6) * 10 + 5, 5, 5);
            doc.text(this.capitalize(this.listPJ[line]), 20, (cpt + 7) * 10);
            cpt++;
          }
        }
      }

      doc.save("mes_justificatifs_retraite.pdf");
    },
  },
};
</script>

<style scoped>
legend {
  font-size: 1em;
}

h2 {
  text-align: center;
  font-size: 1.2em;
}

h3 {
  font-size: 1.1em;
}

.subTitle {
  margin-bottom: 1rem;
}

.intro-text {
  margin: 2rem auto;
}

.position {
  display: inline-block;
  width: 20rem;
}

.text-cpt {
  font-size: 1.125rem;
  font-weight: bold;
  color: #b42342;
  text-align: center;
}

.document {
  width: 15%;
  height: auto;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 0px;
  cursor: pointer;
  top: -0.9rem;
}

.home_container {
  position: relative;
}

#showDocs {
  scroll-behavior: smooth;
}

.btn-block {
  background-color: #339ba9;
  color: #fff;
  font: 1em sans-serif;
}

.listepj {
  font: 1.2rem sans-serif;
}

li::before {
  color: #b42342;
}

.btn {
  background: #b42342;
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.btn:hover {
  background: #8d1a33;
}

.btn.lien {
  background: #fff;
  color: #b42342;
  border: 1px solid #b42342;
  font-size: 14px;
  margin: 0;
}

.btn.lien:hover {
  background: #ccc;
}

.div_btn {
  margin: 1.2rem 1.2rem;
}

.btn_enfant {
  margin-bottom: 10px;
}
</style>
