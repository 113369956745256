<template>
  <div v-show="showFormSF" class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">

      <fieldset>
      <legend class="bloc-title">Quel est votre situation familliale actuelle ?</legend>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="sf1"
          id="sf_veuf"
          value="sf_veuf"
          v-model="form_data.picked_other"
          v-on:change="SetSFOtherVisibility()"
        />
        <label class="form-check-label" for="sf_veuf">Veuf(ve)</label>
      </div>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="sf1"
          id="sf_autre"
          value="sf_autre"
          v-model="form_data.picked_other"
          v-on:change="SetSFOtherVisibility()"
        />
        <label class="form-check-label" for="sf_autre">Autres situations</label>
      </div>
      <div class="col-md-10 ml-auto mr-auto mt-3" v-if="showFormSFOther">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="sf2"
            id="sf_concubin"
            value="sf_concubin"
            v-model="form_data.picked_sf"
            v-on:change="setPJ_sf()"
          />
          <label class="form-check-label" for="sf_concubin">Concubin(e)</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="sf2"
            id="sf_remarie"
            value="sf_remarie"
            v-model="form_data.picked_sf"
            v-on:change="setPJ_sf()"
          />
          <label class="form-check-label" for="sf_remarie">{{
            form_libelle.sf_remarie
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="sf2"
            id="sf_pacs"
            value="sf_pacs"
            v-model="form_data.picked_sf"
            v-on:change="setPJ_sf()"
          />
          <label class="form-check-label" for="sf_pacs">{{
            form_libelle.sf_pacs
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="sf2"
            id="sf_separe"
            value="sf_separe"
            v-model="form_data.picked_sf"
            v-on:change="setPJ_sf()"
          />
          <label class="form-check-label" for="sf_separe">{{
            form_libelle.sf_separe
          }}</label>
        </div>
      </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form_data", "form_libelle", "showFormSF", "showFormSFOther"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    SetSFOtherVisibility() {
      let other = this.form_data.picked_other;

      if (other === "sf_autre") {
        this.$emit("update_showFormSFOther", true);
        this.form_data.picked_sf = "";
      } else {
        this.$emit("update_showFormSFOther", false);
        //reset des PJ Enfant et SF
        this.form_data.picked_sf = other;
      }
      this.$emit("setPJ_sf", this.form_data.picked_sf);
    },
    setPJ_sf() {
      this.$emit("setPJ_sf", this.form_data.picked_sf);
    },
  },
};
</script>

<style scoped>

legend {
  font-size: 1em;
}

</style>