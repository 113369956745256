<template>
  <div class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">
      Bon à savoir : Si vous ne disposez pas d'un scanner, utilisez l'appli Mon
      compte retraite pour scanner vos justificatifs ! Elle dispose d'une
      fonctionnalité qui permet un scan à la bonne taille et au bon format. Ceci
      est indispensable pour une prise en compte de vos documents. Une fois
      scannés avec l'appli, envoyez vos justificatifs sur votre ordinateur pour
      les joindre à votre demande.
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  mounted() {},
  methods: {},
};
</script>
