<template>
  <div class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">

      <fieldset>
      <legend class="bloc-title">Nationalité :</legend>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="is_fr"
          id="is_fr"
          value="fr"
          v-model="form_data.picked_nat"
          v-on:change="setPJ_nat()"
        />
        <label class="form-check-label" for="is_fr">Française</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="is_fr"
          id="is_not_fr"
          value="nat"
          v-model="form_data.picked_nat"
          v-on:change="setPJ_nat()"
        />
        <label class="form-check-label" for="is_not_fr">{{
          form_libelle.nat
        }}</label>
      </div>
    </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form_data", "form_libelle"],
  mounted() {},
  methods: {
    setPJ_nat() {
      //je récupère le choix du user
      let nat = this.form_data.picked_nat;
      this.$emit("setNat", nat);
      nat === "nat"
        ? this.$emit("setBoolNat", true)
        : this.$emit("setBoolNat", false);
    },
  },
};
</script>

<style scoped>

legend {
  font-size: 1em;
}

</style>