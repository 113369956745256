<template>
  <div class="home_container>">
    <div class="col-12 text-center">
      <h2>Demander ma réversion en ligne</h2>
      <hr />
    </div>
    <button class="btn" @click="goBackToHome">Retour à l'accueil</button>
    <div class="row justify-content-center">
      <div class="col-12 text-center intro-text">
        <p class="listepj">
          Préparez votre demande de réversion en ligne en vérifiant les
          documents qui seront nécessaires pour cette démarche.
        </p>
      </div>
      <div class="col-12 text-center message">{{ message }}</div>
    </div>

    <div class="row justify-content-center">
      <div class="col text-cpt">
        Consultez la liste de vos justificatifs
      </div>
    </div>

    <form>
      <!-- BLOC NATIONALITE -->
      <BlocNat :form_data="form_data" :form_libelle="form_libelle" @setNat="(nat) => setPJ_nat(nat)"
        @setBoolNat="bool_nat = $event" />
      <!-- BLOC SITUATION FAMILIALE -->
      <BlocLink :form_data="form_data" :form_libelle="form_libelle" @setLink="(link) => SetSFVisibility(link)" />

      <!-- BLOC SITUATION ACTUELLE -->
      <BlocSF :form_data="form_data" :form_libelle="form_libelle" :showFormSF="showFormSF"
        :showFormSFOther="showFormSFOther" v-on:update_showFormSFOther="showFormSFOther = $event"
        @setPJ_sf="(picked_sf) => setPJ_sf(picked_sf)" />
      <!-- FIN BLOC SITUATION ACTUELLE -->
      <!-- BLOC ENFANT A CHARGE -->
      <BlocEnfant :form_data="form_data" :form_libelle="form_libelle" :showFormSF="showFormSF" @setPJ_enfant_drev="(picked_enfant_drev) => setPJ_enfant_drev(picked_enfant_drev)
      " @setBoolEnfant="bool_enfant_drev = $event" />
      <!-- BLOC ANCIENS COMBATTANTS etc -->
      <BlocHCP :form_data="form_data" :form_libelle="form_libelle" @setPJ_hcp="(picked_hcp) => setPJ_hcp(picked_hcp)"
        @setBoolHcp="bool_hcp = $event" />
    </form>

    <!-- BLOC DISPLAY DOCS -->
    <div class="row justify-content-center" v-show="showDocs" id="showDocs" ref="showDocs">
      <div class="col-md-10 ml-auto mr-auto form_bloc" id="listPJ">
        <p class="listepj">Justificatifs à prévoir :</p>
        <h3>
          Retrouvez ci-dessous les principaux justificatifs que vous devrez
          fournir au moment de faire votre demande de retraite en ligne.
        </h3>
        Voici les documents nécessaires :
        <ul>
          <li v-for="PJNominal in listPJNominal" :key="PJNominal">
            {{ capitalize(PJNominal) }}
            <ModalComponent v-if="PJNominal ==
      'Copie intégrale de votre acte de naissance avec mention marginale reflétant la situation actuelle'
      " url_image="/pj_exemple/acte_de_naissance.png" :PjTitle="PJNominal" :PjId="'Actedenaissance'" />
            <ModalComponent v-if="PJNominal == 'Deux derniers avis d’impôt sur le revenu'"
              url_image="/pj_exemple/avis_impot.png" :PjTitle="PJNominal" :PjId="'Impot'" />
            <ModalComponent v-if="PJNominal ==
      'Copie intégrale de votre livret de famille (situation familiale actuelle)'
      " url_image="/pj_exemple/livret_famille_epoux.jpg" :PjTitle="PJNominal" :PjId="'Epoux'" />
            <ModalComponent v-if="PJNominal == 'Relevé d\'identité bancaire (RIB ou RICE) à votre nom sous format IBAN'"
              url_image="/pj_exemple/specimen_rib.png" :PjTitle="capitalize(PJNominal)" :PjId="'RIB'" />
          </li>
          <li v-show="bool_nat">
            {{ capitalize(this.listPJLongLabel.nat) }}
            <ModalComponent url_image="/pj_exemple/titre_sejour.jpg" :PjTitle="this.listPJLongLabel.nat"
              :PjId="'Veuf'" />
          </li>
          <li v-show="bool_SF.bool_remarie">
            {{ capitalize(this.listPJLongLabel.sf_remarie) }}
          </li>
          <li v-show="bool_SF.bool_pacs">
            {{ capitalize(this.listPJLongLabel.sf_pacs) }}
          </li>
          <li v-show="bool_SF.bool_separe">
            {{ capitalize(this.listPJLongLabel.sf_separe) }}
          </li>
          <li v-show="bool_SF.bool_concubin">
            {{ capitalize(this.listPJLongLabel.sf_concubin) }}
          </li>
          <li v-show="bool_SF.bool_veuf">
            {{ capitalize(this.listPJLongLabel.sf_veuf) }}
            <ModalComponent url_image="/pj_exemple/livret_famille_epoux.jpg" :PjTitle="this.listPJLongLabel.sf_veuf"
              :PjId="'Veuf'" />
          </li>
          <li v-show="bool_enfant_drev">
            {{ capitalize(this.listPJLongLabel.enfant_drev) }}
            <ModalComponent url_image="/pj_exemple/livret_famille_enfant.jpg"
              :PjTitle="this.listPJLongLabel.enfant_drev" :PjId="'Enfant'" />
          </li>
          <li v-show="bool_hcp">
            {{ capitalize(part_hcp(listPJLongLabel.hcp, 0)) }}
            ou
            {{ part_hcp(listPJLongLabel.hcp, 1) }}
            <span>
              <ModalComponent url_image="/pj_exemple/cotorep.png" :PjId="'cotorep'"
                :PjTitle="capitalize(part_hcp(listPJLongLabel.hcp, 1))" />
            </span>
            ou
            {{ part_hcp(listPJLongLabel.hcp, 2) }}
            <span>
              <ModalComponent url_image="/pj_exemple/notification-mdph.png" :PjId="'notification-mdph'"
                :PjTitle="capitalize(part_hcp(listPJLongLabel.hcp, 2))" />
            </span>
            ou
            {{ part_hcp(listPJLongLabel.hcp, 3) }}
            <span>
              <ModalComponent url_image="/pj_exemple/cmi_invalidite.png" :PjId="'cmi_invalidite'"
                :PjTitle="capitalize(part_hcp(listPJLongLabel.hcp, 3))" />
            </span>
          </li>
        </ul>
        <h3>
          D'autres documents pourront vous être demandés au cours de votre
          démarche
        </h3>
        <div class="row gap-3 justify-content-center mt-3">
          <div class="col-auto">
            <a class="btn lien" href="https://www.info-retraite.fr/portail-services/drv" target="_blank"
              v-on:click="$emit('notify', 'accesDrev')" rel="noopener noreferrer">
              <i class="fa fa-lock"></i>Demander ma réversion</a>
          </div>
          <div class="col-auto">
            <div class="btn" v-on:click="generatePDF">Enregistrer en PDF</div>
          </div>
          <div class="col-auto">
            <div class="btn" v-on:click="goToTop">Retour en haut de page</div>
          </div>
        </div>
      </div>
    </div>
    <!--FIN BLOC DISPLAY DOCS -->
    <!-- BLOC INFO SCAN -->
    <BlocInfoScan />
    <!--FIN INFO SCAN -->
  </div>
  <!--FIN BLOC CONTAINER -->
</template>

<script>
import jspdf from "jspdf";
import ModalComponent from "../components/ModalComponent.vue";
import BlocNat from "../components/shared/BlocNatComponent.vue";
import BlocLink from "../components/DREV/BlocLinkComponent.vue";
import BlocSF from "../components/DREV/BlocSFComponent.vue";
import BlocEnfant from "../components/DREV/BlocEnfantComponent.vue";
import BlocHCP from "../components/DREV/BlocHCPComponent.vue";
import BlocInfoScan from "../components/shared/BlocInfoScanComponent.vue";

export default {
  components: {
    BlocNat,
    BlocLink,
    BlocSF,
    BlocEnfant,
    BlocHCP,
    BlocInfoScan,
    ModalComponent,
  },
  data() {
    return {
      api_url: process.env.MIX_APP_API_URL,
      form_data: {
        picked_nat: null,
        picked_sf: "",
        picked_link: "",
        picked_other: "",
        picked_enfant_drev: null,
        picked_hcp: null,
      },
      listDocs: [],
      form_libelle: { nat: "" },
      listPJ: {},
      listPJNominal: [],
      listPJLongLabel: [],
      message: "",
      cpt: 0,
      urlImage: "./images/documents.svg",
      showDocs: true,
      showFormSF: false,
      showFormSFOther: false,
      bool_nat: false,
      bool_hcp: false,
      bool_enfant_drev: false,
      bool_SF: {
        bool_remarie: false,
        bool_pacs: false,
        bool_separe: false,
        bool_concubin: false,
        bool_veuf: false,
      },
    };
  },
  created() {
    this.getPJs();
    this.changeTitle();
  },
  beforeUpdate() {
    let pj_entries = Object.keys(this.listPJ);
    //on est obligé de ne pas compter les enfants car on a pas pu ajouter à la liste des PJ la concat enf_+index
    //to study later
    pj_entries = pj_entries.filter(function (item) {
      return !item.includes("enf_");
    });
    this.cpt = pj_entries.length - 1; //on retire le cas_nominal
  },
  methods: {
    changeTitle() {
      var title = document.querySelector("#title");
      title.innerHTML = "Justificatifs à fournir";
    },
    goBackToHome() {
      this.$emit("notify", "/");
      this.$router.push("/");
    },
    getOldKey(prefix_key) {
      //on recupere sous forme de tableau les clés de l'obj
      const props = Object.keys(this.listPJ);
      let old_picked_key = props.find((key) => {
        let index = key.indexOf(prefix_key);
        if (index !== -1) return key;
      });
      return old_picked_key;
    },
    displayDocs() {
      //this.showDocs = !this.showDocs;
      let element = this.$refs["showDocs"];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
    //not used;
    getImgUrl() {
      return "./images/documents.svg";
    },
    getPJ(field) {
      return this.listDocs.find((item) => {
        return item.short_label_pj == field;
      });
    },
    setPJ_nat(nat) {
      //si le choix est fr pas de PJ nat
      if (nat === "fr") {
        delete this.listPJ.nat;
      } else if (nat === "nat" && !this.listPJ[nat]) {
        //j'ajoute la PJ que si ce n'est pas fr et que ce n'est pas déja inside'
        this.setPJ(nat);
      }
    },
    setBoolSFToFalse() {
      for (const bool in this.bool_SF) {
        this.bool_SF[bool] = false;
      }
    },
    setBoolSF(sf) {
      if (sf) {
        switch (sf.slice(3)) {
          case "separe":
            this.setBoolSFToFalse();
            this.bool_SF.bool_separe = true;
            break;
          case "remarie":
            this.setBoolSFToFalse();
            this.bool_SF.bool_remarie = true;
            break;
          case "pacs":
            this.setBoolSFToFalse();
            this.bool_SF.bool_pacs = true;
            break;
          case "concubin":
            this.setBoolSFToFalse();
            this.bool_SF.bool_concubin = true;
            break;
          case "veuf":
            this.setBoolSFToFalse();
            this.bool_SF.bool_veuf = true;
            break;
          default:
            this.setBoolSFToFalse();
            break;
        }
      } else {
        this.setBoolSFToFalse();
      }
    },
    setPJ_sf(sf) {
      //on recherche la dernière picked_sf enregitrée pour l'effacer
      let old_picked_sf = this.getOldKey("sf_");
      if (old_picked_sf) {
        //remove old_picked_sf
        delete this.listPJ[old_picked_sf];
      }
      //on ajoute la pièce jointe correspondante sauf s'il s'agit d'un single OU d'un pacsé
      this.setBoolSF(sf);
      this.setPJ(sf);
    },
    SetSFVisibility(link) {
      this.showFormSFOther = false;
      if (link === "link_conjoint") {
        this.showFormSF = true;
      } else {
        this.showFormSF = false;
        //reset des PJ Enfant et SF
        this.form_data.picked_other = "";
        this.form_data.picked_enfant_drev = "";
        this.form_data.picked_sf = "";
        this.setPJ_enfant_drev();
        this.setPJ_sf();
      }
    },
    setPJ_enfant_drev(enfant_drev) {
      if (enfant_drev != "enfant_drev") delete this.listPJ.enfant_drev;
      //j'ajoute la PJ que si ce n'est pas hcp et que ce n'est pas déja inside'
      if (!this.listPJ[enfant_drev] && enfant_drev !== "non_enfant_drev")
        this.setPJ(enfant_drev);
    },
    // découpe la phrase pour le cas invalidité et renvoie la partie demandée
    part_hcp(txt, n) {
      if (txt) {
        const parts = txt.split(' ou ')
        return parts[n]
      }
      return ""
    },
    setPJ_hcp(hcp) {
      if (hcp != "hcp") delete this.listPJ.hcp;
      //j'ajoute la PJ que si ce n'est pas hcp et que ce n'est pas déja inside'
      if (!this.listPJ[hcp] && hcp !== "non_hcp") this.setPJ(hcp);
    },
    getLabels() {
      this.listDocs.map((item) => {
        this.form_libelle[item.short_label_pj] = item.situation;
      });
    },
    initlistPJ() {
      let pj = this.getPJ("cas_nominal_drev");
      if (pj) this.listPJ["cas_nominal_drev"] = pj.long_label_pj;
      this.listPJNominal = this.listPJ["cas_nominal_drev"].split(" | ");
    },
    setPJ(field) {
      //field not nec !
      //on veut ajouter la pJ <=> une situation sous la forme un clé valeur
      let pj = this.getPJ(field);
      if (pj) {
        //let objPJ = `{ ${field}:${pj.long_label_pj} }`;
        // this.listPJ = Object.assign({}, this.listPJ, objPJ);
        this.listPJ[field] = pj.long_label_pj;
      }
    },
    initListPJLongLabel(datas) {
      for (let i = 0; i < datas.length; i++) {
        this.listPJLongLabel[datas[i].short_label_pj] = datas[i].long_label_pj;
      }
    },
    getPJs() {
      axios
        .get(`${this.api_url}`)
        .then((response) => {
          // let i = this.reponses.map((data) => data.id).indexOf(id);
          // this.reponses.splice(i, 1);
          //on recupère l'ensemble des pièces jointes pour toutes les siutations
          this.listDocs = response.data;
          this.initListPJLongLabel(this.listDocs);
          //on récupère les libelles de toutes les situations
          this.getLabels();
          this.initlistPJ();

          //window.scrollTo(0,0);
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            this.message =
              "Une erreur est survenue, nous vous invitons à revenir plus tard";
          }
        });
    },
    capitalize(str) {
      // converting first letter to uppercase
      if (str) {
        const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
        return capitalized;
      }
    },
    generatePDF() {
      this.$emit("notify", "PJDrev");
      const divPJ = document.getElementById("listPJ");
      var doc = new jspdf("p", "mm", "a4");
      var cpt = 0;
      doc.setFontSize(12);

      // All units are in the set measurement for the document
      // This can be changed to "pt" (points), "mm" (Default), "cm", "in"
      var tabPJ = [];
      for (const key in this.listPJ) {
        tabPJ.push(key);
      }
      doc.text("Voici les documents dont vous avez besoin :", 20, 20);
      for (let i = 0; i < this.listPJNominal.length; i++) {
        if (i == 4) {
          doc.rect(10, (i + 2) * 10 + 5, 5, 5);
          let string1 = this.listPJNominal[i].substr(0, 83);
          let string2 = this.listPJNominal[i].substr(84);
          doc.text(this.capitalize(string1), 20, (i + 3) * 10);
          cpt = 1;
          doc.text(this.capitalize(string2), 20, (i + cpt + 3) * 10);
        } else {
          doc.rect(10, (i + cpt + 2) * 10 + 5, 5, 5);
          doc.text(
            this.capitalize(this.listPJNominal[i]),
            20,
            (i + cpt + 3) * 10
          );
        }
      }
      cpt = 0;
      for (let i = 0; i < tabPJ.length; i++) {
        const line = tabPJ[i];
        if (line != "cas_nominal_drev") {
          if (line == "hcp") {
            doc.rect(10, (i + 8) * 10 + 5, 5, 5);
            let string1 = this.listPJ[line].substr(0, 83);
            let string2 = this.listPJ[line].substr(84);
            doc.text(this.capitalize(string1), 20, (i + 9) * 10);
            cpt = 1;
            doc.text(string2, 20, (i + cpt + 9) * 10);
          } else {
            doc.rect(10, (i + 8) * 10 + 5, 5, 5);
            doc.text(this.capitalize(this.listPJ[line]), 20, (i + 9) * 10);
          }
        }
      }

      doc.save("mes_justificatifs_de_reversion.pdf");
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  font-size: 1.2em;
}

h3 {
  font-size: 1.1em;
}

.subTitle {
  margin-bottom: 1rem;
}

.intro-text {
  margin: 2rem auto;
}

.position {
  display: inline-block;
  width: 20rem;
}

.text-cpt {
  font-size: 1.125rem;
  font-weight: bold;
  color: #b42342;
  text-align: center;
}

.document {
  width: 15%;
  height: auto;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 0px;
  cursor: pointer;
  top: -0.9rem;
}

.home_container {
  position: relative;
}

#showDocs {
  scroll-behavior: smooth;
}

.btn-block {
  background-color: #339ba9;
  color: #fff;
  font: 1em sans-serif;
}

.listepj {
  font: 1.2rem sans-serif;
}

li::before {
  color: #b42342;
}

.btn {
  background: #b42342;
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.btn:hover {
  background: #8d1a33;
}

.btn.lien {
  background: #fff;
  color: #b42342;
  border: 1px solid #b42342;
  font-size: 14px;
  margin: 0;
}

.btn.lien:hover {
  background: #ccc;
}

.div_btn {
  margin: 1.2rem 1.2rem;
}
</style>
