<template>
  <div class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">

      <fieldset>
      <legend class="bloc-title">Quel est votre lien de parenté avec la personne décédée ?</legend>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="link"
          id="link_enfant"
          value="link_enfant"
          v-model="form_data.picked_link"
          v-on:change="setPJ_link()"
        />
        <label class="form-check-label" for="link_enfant">Enfant</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="link"
          id="link_conjoint"
          value="link_conjoint"
          v-model="form_data.picked_link"
          v-on:change="setPJ_link()"
        />
        <label class="form-check-label" for="link_conjoint"
          >Conjoint(e) ou ex-conjoint(e)</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="link"
          id="link_autre"
          value="link_autre"
          v-model="form_data.picked_link"
          v-on:change="setPJ_link()"
        />
        <label class="form-check-label" for="link_autre">Autre</label>
      </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form_data", "form_libelle"],
  mounted() {},
  methods: {
    setPJ_link() {
      //je récupère le choix du user
      let link = this.form_data.picked_link;
      this.$emit("setLink", link);
    },
  },
};
</script>

<style scoped>

legend {
  font-size: 1em;
}

</style>