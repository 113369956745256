<template>
  <div class="row">
    <div class="container mainpage">
      <router-view v-on:notify="dataLayerOnPageLoad($event)"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    dataLayerOnPageLoad(pathname) {
      let metaDescr = document.querySelector('meta[name="description"]');
      let descrTab = {
        home: "Préparez vos démarches en ligne en vérifiant les principaux documents qui vous seront demandés dans les services grâce à cet outil Info Retraite.",
        dril: "Préparez la demande de réversion en ligne en vérifiant les documents qui vous seront demandés grâce à cet outil Info Retraite.",
        drev: "Préparez la demande de retraite en ligne en vérifiant les documents qui vous seront demandés grâce à cet outil Info Retraite.",
      };
      switch (pathname) {
        case "/":
          document.title = "Mes justificatifs - Info Retraite";
          metaDescr.setAttribute("content", descrTab.home);
          dataLayer.push({
            event: "pushVariables",
            page: {
              name: "accueil",
              chapter1: "",
              chapter2: "",
              level2: "29",
              url: "https://mesjustificatifs.info-retraite.fr",
            },
          });
          break;
        case "/drev":
          document.title =
            "Mes justificatifs demander ma réversion en ligne - Info Retraite";
          metaDescr.setAttribute("content", descrTab.drev);

          dataLayer.push({
            event: "pushVariables",
            page: {
              name: "pj_reversion",
              chapter1: "",
              chapter2: "",
              level2: "29",
              url: "https://mesjustificatifs.info-retraite.fr/drev",
            },
          });
          break;
        case "/dril":
          document.title =
            "Mes justificatifs demander ma retraite en ligne - Info Retraite";
          metaDescr.setAttribute("content", descrTab.dril);
          dataLayer.push({
            event: "pushVariables",
            page: {
              name: "pj_retraite",
              chapter1: "",
              chapter2: "",
              level2: "29",
              url: "https://mesjustificatifs.info-retraite.fr/dril",
            },
          });
          break;
        case "PJDril":
          dataLayer.push({
            event: "interaction",
            name: "enregistrer_pdf",
            chapter1: "pj_retraite",
            chapter2: "",
            level2: "29",
            type: "download",
          });
          break;
        case "PJDrev":
          dataLayer.push({
            event: "interaction",
            name: "enregistrer_pdf",
            chapter1: "pj_reversion",
            chapter2: "",
            level2: "29",
            type: "download",
          });

          break;
        case "accesDril":
          dataLayer.push({
            event: "interaction",
            name: "acces_dril",
            chapter1: "pj_retraite",
            chapter2: "",
            level2: "29",
            type: "download",
          });

          break;
        case "accesDrev":
          dataLayer.push({
            event: "interaction",
            name: "acces_drev",
            chapter1: "pj_reversion",
            chapter2: "",
            level2: "29",
            type: "download",
          });

          break;
      }
    },
  },
};
</script>

<style scoped>
.mainpage {
  margin: 1.2rem auto;
}
</style>
