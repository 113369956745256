<template>
  <div class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">

      <fieldset>
        <legend class="bloc-title">Situation familliale :</legend>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="sf1" id="sf_other" value="sf_other"
            v-on:change="setPJ_sf()" v-model="form_data.picked_sf" />
          <label class="form-check-label" for="sf_other">Célibataire</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="sf1" id="sf_marie" value="sf_marie"
            v-on:change="setPJ_sf()" v-model="form_data.picked_sf" />
          <label class="form-check-label" for="sf_marie">Marié(e)</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="sf1" id="sf_pacs" value="sf_pacs" v-on:change="setPJ_sf()"
            v-model="form_data.picked_sf" />
          <label class="form-check-label" for="sf_pacs">Pacsé(e)</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="sf1" id="sf_div" value="sf_div" v-on:change="setPJ_sf()"
            v-model="form_data.picked_sf" />
          <label class="form-check-label" for="sf_div">Divorcé(e)</label>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form_data", "form_libelle"],
  data() {
    return {};
  },
  mounted() { },
  methods: {
    setPJ_sf() {
      this.$emit("setPJ_sf", this.form_data.picked_sf);
    },
  },
};
</script>

<style scoped>
legend {
  font-size: 1em;
}
</style>