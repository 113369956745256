import { render, staticRenderFns } from "./BlocLinkComponent.vue?vue&type=template&id=14cd17ae&scoped=true&"
import script from "./BlocLinkComponent.vue?vue&type=script&lang=js&"
export * from "./BlocLinkComponent.vue?vue&type=script&lang=js&"
import style0 from "./BlocLinkComponent.vue?vue&type=style&index=0&id=14cd17ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14cd17ae",
  null
  
)

export default component.exports