import Home from './views/Home'
import Dril from './views/Dril'
import Drev from './views/Drev'

export const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/dril',
        name: 'dril',
        component: Dril
    },
    {
        path: '/drev',
        name: 'Drev',
        component: Drev
    },
]