<template>
  <span>
    <!-- Button trigger modal -->
    <i
      class="fa-solid fa-circle-info"
      type="button"
      data-bs-toggle="modal"
      :data-bs-target="'#' + PjId"
    ></i>
    <!-- Modal -->
    <div
      class="modal fade"
      :id="PjId"
      tabindex="-1"
      aria-labelledby="specimenModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog mw-100">
        <div class="modal-content container">
          <div class="modal-header">
            <p class="modal-title text-center" id="specimenModalLabel">
              <strong>
                Ce spécimen est présenté à titre d’exemple.
                Votre pièce justificative peut être différente en fonction notamment de sa date de production ou de l'administration émettrice.
              </strong>
              <br />
              {{ PjTitle }}
            </p>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <img
              class="img-fluid"
              :src="'/images/' + url_image"
              alt="Specimen de la pièce jointe"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: ["url_image", "PjTitle", "PjId"],
  mounted() {},
  methods: {},
};
</script>
