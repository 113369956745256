<template>
  <div v-show="showFormSF" class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">

      <fieldset>
      <legend class="bloc-title">Avez-vous un enfant à charge ?</legend>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="enfant_drev"
          id="enfant_drev"
          value="enfant_drev"
          v-model="form_data.picked_enfant_drev"
          v-on:change="setPJ_enfant_drev()"
        />
        <label class="form-check-label" for="enfant_drev">Oui</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="enfant_drev"
          id="non_enfant_drev"
          value="non_enfant_drev"
          v-model="form_data.picked_enfant_drev"
          v-on:change="setPJ_enfant_drev()"
        />
        <label class="form-check-label" for="non_enfant_drev">Non</label>
      </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form_data", "form_libelle", "showFormSF"],
  mounted() {},
  methods: {
    setPJ_enfant_drev() {
      //je récupère le choix du user
      let picked_enfant_drev = this.form_data.picked_enfant_drev;
      this.$emit("setPJ_enfant_drev", picked_enfant_drev);
      picked_enfant_drev === "enfant_drev"
        ? this.$emit("setBoolEnfant", true)
        : this.$emit("setBoolEnfant", false);
    },
  },
};
</script>

<style scoped>

legend {
  font-size: 1em;
}

</style>