<template>
  <div class="home_container>">
    <h2 class="mb-4">
      Préparez vos démarches en ligne en vérifiant les principaux documents qui
      vous seront demandés dans les services.
    </h2>
    <div class="card-container">
      <div class="row row-cols-1 row-cols-md-2 justify-content-center g-4">
        <div class="col">
          <div class="card">
            <img src="/images/checklist.svg" alt="Logo demander ma réversion" @click="goToDrev" />
            <h3>Demander ma réversion</h3>
            <button class="btn" @click="goToDrev">
              Accéder à la liste des justificatifs
            </button>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img src="/images/checklist2.svg" alt="Logo demander ma retraite" @click="goToDril" />
            <h3>Demander ma retraite</h3>
            <button class="btn" @click="goToDril">
              Accéder à la liste des justificatifs
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.changeTitle();
  },
  methods: {
    changeTitle() {
      var title = document.querySelector("#title");
      title.innerHTML = "Justificatifs à fournir pour vos démarches";
    },
    goToDril() {
      this.$emit("notify", "/dril");
      this.$router.push("/dril");
    },
    goToDrev() {
      this.$emit("notify", "/drev");
      this.$router.push("/drev");
    },
  },
};
</script>

<style scoped>
.intro-text {
  margin: 2rem auto;
}

h2 {
  text-align: center;
  font-size: 1.2em;
}

h3 {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.4em;
}

.card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 10px 5px 5px #ccc;
  transition: all 300ms ease-in-out;
  padding: 1rem;
}

.card img {
  cursor: pointer;
}

.card:hover {
  box-shadow: 10px 5px 5px rgb(112, 112, 112);
}

img {
  height: 20vh;
}

.position {
  padding: 1.5rem;
  display: inline-block;
  width: 20rem;
}

.text-cpt {
  font-size: 1.125rem;
  font-weight: bold;
  color: #b42342;
  padding-left: 7.5rem;
}

.document {
  width: 35%;
  height: auto;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 0px;
  cursor: pointer;
  top: -0.9rem;
}

.home_container {
  position: relative;
}

#showDocs {
  scroll-behavior: smooth;
  cursor: pointer;
}

.btn-block {
  background-color: #339ba9;
  color: #fff;
  font: 1em sans-serif;
}

.listepj {
  font: 1.2rem sans-serif;
}

.cas_nominal {
  list-style-type: none;
  padding-bottom: 2rem;
}

.cas_nominal::before {
  content: "▶";
  padding-right: 8px;
  color: #339ba9;
}

li::before {
  color: #b42342;
}

.btn {
  background: #008294;
  border: 1px solid #008294;
  color: #fff;
  margin-top: 5px;
  font-size: 18px;
}

.btn:hover {
  color: #008294;
  background: #fff;
}

.dl_link {
  color: #b42342;
  cursor: grab;
  text-decoration: underline;
}

.dl_link:hover {
  color: #871c33;
  cursor: pointer;
  text-decoration: underline;
}
</style>
