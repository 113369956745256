<template>
  <div class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">

      <fieldset>
      <legend class="bloc-title">Etes vous en situation de handicap ou d'invalidité ?</legend>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="hcp"
          id="hcp"
          value="hcp"
          v-model="form_data.picked_hcp"
          v-on:change="setPJ_hcp()"
        />
        <label class="form-check-label" for="hcp">Oui</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="hcp"
          id="non_hcp"
          value="non_hcp"
          v-model="form_data.picked_hcp"
          v-on:change="setPJ_hcp()"
        />
        <label class="form-check-label" for="non_hcp">Non</label>
      </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form_data", "form_libelle", "showFormSF"],
  mounted() {},
  methods: {
    setPJ_hcp() {
      //je récupère le choix du user
      let picked_hcp = this.form_data.picked_hcp;
      this.$emit("setPJ_hcp", picked_hcp);
      picked_hcp === "hcp"
        ? this.$emit("setBoolHcp", true)
        : this.$emit("setBoolHcp", false);
    },
  },
};
</script>

<style scoped>

legend {
  font-size: 1em;
}

</style>