<template>
  <div class="row justify-content-center">
    <div class="col-md-10 ml-auto mr-auto form_bloc">

      <fieldset>
        <legend class="bloc-title">Situation particulière :</legend>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" name="hcp" id="hcp" v-model="form_data.picked_hcp"
            v-on:change="setPJ('setPJ_hcp')" />
          <label class="form-check-label" for="hcp">En situation de handicap ou d'invalidité</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" name="maladie" id="maladie" value="maladie"
            v-model="form_data.picked_maladie" v-on:change="setPJ('setPJ_maladie')" />
          <label class="form-check-label" for="maladie">Salarié(e) du secteur privé ou agricole, actuellement en
            accident du
            travail ou maladie professionnelle</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" name="chomage" id="chomage" value="chomage"
            v-model="form_data.picked_chomage" v-on:change="setPJ('setPJ_chomage')" />
          <label class="form-check-label" for="chomage">Salarié(e) du secteur privé ou agricole, actuellement au chômage
          </label>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form_data", "form_libelle"],
  data() {
    return {};
  },
  mounted() { },
  methods: {
    setPJ(PJ_type) {
      this.$emit(PJ_type, this.form_data);
    },
  },
};
</script>

<style scoped>
legend {
  font-size: 1em;
}
</style>